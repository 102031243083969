<template>
  <div id="app" @click="saltarIntro">
    <nav-bar :introDone="introDone" />
    <div id="emailContacto">
      contact@sindamanoymedia.com
    </div>
    <video
      id="videoPortada"
      autoplay
      playsinline="playsinline"
      :style="[estiloVideoPortada]"
      :class="{izq:ajustarVideoIzq}"
    >
      <source src="@/assets/portada.mp4" />
    </video>
    <img
      src="@/assets/audio.png"
      alt="Activar audio"
      id="botonAudioPortada"
      :title="audioPortada ? 'Desactivar audio' : 'Activar audio'"
      width="25px"
      v-show="videoPlaying"
      @click.stop="audioPortada = !audioPortada"
    />
    <transition name="fadeInOut">
    <div id="zonaUnderConstruction" v-show="introDone">
      <p>Page under construction</p>
      <div id="zonaBarraProgreso">
        <img src="@/assets/hard-hat-solid.svg" alt="Construccion" />
        <div class="barraProgreso out">
          <div class="barraProgreso in"></div>
        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import NavBar from "./components/navBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      estiloVideoPortada: {},
      audioPortada: false,
      videoPlaying: false,
      timeVideo:0,
    };
  },
  methods: {
    async playPortada() {
      await document.getElementById("videoPortada").play();
      this.videoPlaying = true;
    },
    unmute() {
      this.$nextTick(() => {
        document.getElementById("videoPortada").muted = false;
      });
    },
    saltarIntro() {
      const videoPortada = document.getElementById("videoPortada");
      if (videoPortada.currentTime < 20) {
        videoPortada.currentTime = 20;
        if (videoPortada.paused) {
          this.playPortada();
        }
      }
    },
  },
  computed:{
    introDone(){
      return this.timeVideo>20;
    },
    ajustarVideoIzq(){
      return this.timeVideo>20.5;
    }
  },
  watch: {
    audioPortada(valor) {
      if (valor) {
        this.aud;
        this.$nextTick(() => {
          document.getElementById("videoPortada").muted = false;
        });
      } else {
        this.$nextTick(() => {
          document.getElementById("videoPortada").muted = true;
        });
      }
    },
  },
  mounted() {
    document.title = "Sindamanoy";
    const videoPortada = document.getElementById("videoPortada");
    videoPortada.addEventListener(
      "ended",
      () => {
        this.videoPlaying = false;
      },
      false
    );
    videoPortada.addEventListener(
      "timeupdate",
      () => {
        this.timeVideo=document.getElementById("videoPortada").currentTime;        
      },
      false
    );
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.saltarIntro();
      }
    });
    if (videoPortada.paused) {
      videoPortada.muted = true;
      this.playPortada();
    }
    
  },
};
</script>

<style>
:root {
  --orangeMain: #da7b4b;
}
body {
  background-color: black;
  margin: 0px 0px;
  padding: 0px;
  width: 99vw;
  height: 98vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position:relative
}
#emailContacto{
  font-size: 14px;
  color: var(--orangeMain);
  z-index: 10;  
  position: absolute;
  bottom: 5px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
#videoPortada {
  position: absolute;
  display: block;
}


#navBar {
  position: fixed;
  top: 45px;
  right: 35px;
}
#botonAudioPortada {
  position: fixed;
  top: 13px;
  right: 13px;
  width: 13px;
  z-index: 10;
  cursor: pointer;
}

#zonaUnderConstruction {
  position: fixed;
  bottom: 0px;
  right: 20px;
  color: var(--orangeMain);
  font-weight: bold;
  padding: 20px;
}
#zonaUnderConstruction img {
  width: 26px;
  filter: invert(59%) sepia(91%) saturate(728%) hue-rotate(326deg)
    brightness(90%) contrast(88%);
    margin-right: 10px;
}
#zonaBarraProgreso{
  display: flex;
  align-items: center;
}
.barraProgreso {
  height: 15px;
  border-radius: 7px;
  width: 260px;
}
.barraProgreso.in {
  background-color: var(--orangeMain);
  width: 40%;
  animation: crecerDecrecer 3s ease-in-out infinite alternate;
}
.barraProgreso.out {
  background-color: blanchedalmond;
}
@media (max-aspect-ratio: 9/5){
  #videoPortada{
    width: 100%;
    top:50%;
    transform: translateY(-50%);
  }
}
@media (min-aspect-ratio: 9/5){
  #videoPortada{
    height: 100%;
  }
  #videoPortada:not(.izq){
  left: 50%;
  transform: translateX(-50%);
  transition: left 0.2s, transform 0.2s;
}
  #videoPortada.izq{
  left: 0px;
  transform: translateX(0%);
}
}

@keyframes crecerDecrecer{
  0%{width: 43%}
  100%{width: 45%;}
}


.fadeInOut-enter, .fadeInOut-leave-to{
  opacity: 0;
}
.fadeInOut-enter-active, .fadeInOut-leave-active{
  transition: opacity 5s;
}
</style>
