<template>
  <transition name="fadeIn">
    <div id="navBar" v-show="introDone && false">
      <img
        src="@/assets/menu.png"
        width="30px"
        alt="menu"
        id="botonMenu"
        @click="mostrandoListaBotones = !mostrandoListaBotones"
      />
      <div id="listaBotones" :class="{ listaOculta: !mostrandoListaBotones }">
        <div class="botonNavegacion">Inicio</div>
        <div class="botonNavegacion">Productos</div>
        <div class="botonNavegacion">Contacto</div>
        <div class="botonNavegacion">Galería</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    introDone: Boolean,
  },
  data() {
    return {
      mostrandoListaBotones: false,
    };
  },
};
</script>

<style scoped>
#navBar {
  transition: opacity 7s;
  z-index: 100;
}
#botonMenu {
  cursor: pointer;
  margin: 0px auto;
  display: block;
  margin-bottom: 20px;
}
#listaBotones {
  flex-direction: column;
}
.botonNavegacion {
  padding: 5px 30px;
  color: #cf7144;
  cursor: pointer;
  position: relative;
  user-select: none;
}
.botonNavegacion:after {
  content: "";
  width: 0%;
  height: 1px;
  background-color: #cf7144;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.6s;
}
.botonNavegacion:hover:after {
  width: 90%;
}
.botonNavegacion:hover {
  color: #da9576;
}
.listaOculta {
  display: none;
}
@media only screen and (min-width: 768px) {
  #botonMenu {
    display: none;
  }
  #listaBotones {
    flex-direction: row;
  }
  .listaOculta {
    display: flex;
  }
}

.fadeIn-enter {
  opacity: 0;
}

.fadeIn-enter-active {
}
</style>